<template>
  <el-select class="accounts_select" v-model="accountID" data-testid="accountNumber">
    <el-option
      v-for="item in rebatesList"
      :key="item.login"
      :value="item.login"
      :label="item.login"
      :data-testid="item.login"
    >
    </el-option>
  </el-select>
</template>

<script>
import { apiQueryRebatesList } from '@/resource';

export default {
  data() {
    return {
      accountID: null,
      rebatesList: []
    };
  },
  watch: {
    accountID(accountID) {
      this.updateAccount();
    }
  },
  mounted() {
    this.queryRebatesList();
  },
  methods: {
    updateAccount() {
      const foundAccount = this.rebatesList.find(f => f.login === this.accountID);
      this.$store.commit('behavior/setAID', foundAccount ? this.accountID : '');
      this.$store.commit('behavior/setCUR', foundAccount ? foundAccount.currency : '');
      this.$store.commit('behavior/setBalance', foundAccount ? foundAccount.commission : 0);
      this.$store.commit('behavior/setDataSourceId', foundAccount ? foundAccount.dataSourceId : null);
      this.$emit('accountCallback');
    },
    queryRebatesList() {
      return apiQueryRebatesList().then(resp => {
        if (resp.data.code === 0) {
          this.rebatesList = resp.data.data.list;
          this.accountID = this.rebatesList[0] ? this.rebatesList[0].login : null;

          if (!this.accountID) this.updateAccount();
        }
      }, this.$handleError);
    }
  }
};
</script>

